export enum FirmBatchTransferType {
    InvestEdge = 'InvestEdge',
    FiTek = 'FiTek',
    None = 'None',
}

export const FIRM_BATCH_TRANSFER_TYPES = [
    { label: 'InvestEdge', value: FirmBatchTransferType.InvestEdge },
    { label: 'FiTek', value: FirmBatchTransferType.FiTek },
    { label: 'None', value: FirmBatchTransferType.None },
];
